import { ReportViewsAndCounts, ViewsAndCounts } from '../types'

export const parseViewsCounts = (
	viewsCounts: Partial<ViewsAndCounts>
): ReportViewsAndCounts => {
	const parseToNumber = (value: number | string | null | undefined): number =>
		typeof value === 'string' ? parseFloat(value) : value ?? 0

	return {
		amount: parseToNumber(
			viewsCounts?.amount
				? viewsCounts.amount
				: viewsCounts.booking_budget
		),
		fact_cost: parseToNumber(viewsCounts.fact_cost),
		forecast_views_count: viewsCounts.forecast_views_count ?? 0,
		views_count: viewsCounts.views_count ?? 0,
	}
}
