import React from 'react'
import s from './photoReport.module.scss'
import { ShowingRequest } from '../../../types'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

type Props = {
	photoReport: ShowingRequest[]
	onClose: () => void
}
export const PhotoReport = ({ photoReport, onClose }: Props) => {
	return (
		<Modal
			show={photoReport}
			onHide={onClose}
			style={{ zIndex: '9999' }}
			backdropClassName={s.photoReportBackdrop}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					Фотоотчет
				</Modal.Title>
			</Modal.Header>

			<Modal.Body
				className={'p-0'}
				style={{
					position: 'relative',
					maxWidth: '100vw',
					overflow: 'hidden',
				}}
			>
				{photoReport?.length ? (
					<div className={s.photoReport}>
						{photoReport.map((el) => {
							const date = moment(
								el.display_dt,
								'YYYY-MM-DD'
							).format('D MMMM')
							return (
								<div key={date} className={s.photoReportItem}>
									<p>{date}</p>
									<ImageFullScreenWrapper fullWidth>
										<img src={el.photo_report} alt={date} />
									</ImageFullScreenWrapper>
								</div>
							)
						})}
					</div>
				) : (
					<h4>Нет данных</h4>
				)}
			</Modal.Body>
		</Modal>
	)
}
