import React, { useEffect, useMemo, useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment'
import 'moment/locale/ru'
import bem from '../../utils/bem'
import { Form, InputGroup } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import Dropdown from './Dropdown'
import arrow from '../../assets/images/calendar-arrow.svg'
import yearArrow from '../../assets/images/calendar-year-arrow.svg'
import '../../styles/custom-date-picker.scss'
import { ReactComponent as CalendarIcon } from 'src/assets/images/calendar.svg'

export function normalizeMomentDate(momentDate) {
	return momentDate ? momentDate.toDate() : momentDate
}

function getDate(string: string) {
	if (string.length === 10) {
		const res = /(\d{2})\.(\d{2})\.(\d{4})/.exec(string)
		if (res) {
			const [, day, month, year] = res
			if (!day || !month || !year) {
				return new Date()
			} else {
				return new Date(+year, +month - 1, +day)
			}
		} else {
			return new Date()
		}
	} else {
		return new Date()
	}
}

const cn = bem('custom-date-picker')
//TODO TYPESCRIPT!!!

export default function DateInput({
	value,
	onChange,
	error,
	title = '' as string | React.ReactNode,
	disabled = false,
	className = '',
	firstAvaliableDate,
	withCheck = true,
	type = '',
	comparisonDay = null as any,
}) {
	const [pendingValue, setPendingValue] = useState(value)
	const [mask, setMask] = useState(pendingValue ? '99.99.9999' : '')
	const dateValue = useMemo(() => getDate(pendingValue), [pendingValue])
	const [currentMonth, setCurrentMonth] = useState(firstAvaliableDate)
	const [key, setKey] = useState(0)

	const [open, setOpen] = useState(() => {
		return moment(value, 'DD.MM.YYYY', true).isValid()
	})

	const handleFocus = () => {
		setMask('99.99.9999')
	}
	const handleBlur = () => {
		if (!pendingValue) {
			setMask('')
		}
	}

	useEffect(() => {
		setPendingValue(value)
	}, [value])

	function handleChange(v) {
		setPendingValue(v)

		const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/

		if (dateRegex.test(v)) {
			// Parse the date string using moment
			const newDate = moment(v, 'DD.MM.YYYY', true)

			// Check if the parsed date is valid
			if (newDate.isValid()) {
				onChange(newDate.toDate())
			}
		}
	}

	function handleCurrentMonthChange(date) {
		setCurrentMonth(date)
		setKey((k) => (k ? 0 : 1))
	}

	return (
		<div className={cn('input', bem.pass(className))}>
			<Dropdown
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				disabled={disabled}
				additionalStyles={{ transform: 'translateY(-32px)' }}
				menu={({ close }) => (
					<div key={key}>
						<DatePicker
							value={dateValue}
							onChange={(date) => {
								const text = moment(date).format('DD.MM.YYYY')
								handleChange(text)
								close()
							}}
							initialVisibleMonth={() => currentMonth}
							onPrevYear={() =>
								handleCurrentMonthChange(
									currentMonth.clone().subtract(1, 'y')
								)
							}
							onNextYear={() =>
								handleCurrentMonthChange(
									currentMonth.clone().add(1, 'y')
								)
							}
							firstAvaliableDate={firstAvaliableDate}
							withCheck={withCheck}
							comparisonDay={comparisonDay}
						/>
					</div>
				)}
				trigger={
					<InputGroup hasValidation>
						{!!title && (
							<Form.Label
								className="m-0"
								onClick={(e) => {
									e.stopPropagation()
								}}
							>
								{title}
							</Form.Label>
						)}

						<InputMask
							id={
								type === 'start'
									? 'start-picker'
									: type === 'end'
									? 'end-picker'
									: 'dropdown-picker'
							}
							mask={mask}
							value={pendingValue}
							placeholder={
								type === 'start'
									? 'Начало'
									: type === 'end'
									? 'Окончание'
									: ''
							}
							onChange={(e) => {
								handleChange(e.target.value)
							}}
							disabled={disabled}
						>
							{(inputProps) => (
								<div style={{ position: 'relative' }}>
									<Form.Control
										onFocus={handleFocus}
										onBlur={handleBlur}
										size="lg"
										type={'text'}
										isInvalid={!!error}
										disabled={disabled}
										{...inputProps}
										style={{
											fontSize: '16px',
											fontWeight: 400,
											lineHeight: '24px',
											textAlign: 'left',
											backgroundImage: 'none',
										}}
									/>

									<CalendarIcon
										style={{
											cursor: 'pointer',
											position: 'absolute',
											top: '50%',
											right: '12px',
											transform: 'translateY(-50%)',
											fill: '#6C757D',
										}}
									/>
								</div>
							)}
						</InputMask>
						<Form.Control.Feedback
							type="invalid"
							style={{ display: error ? 'block' : 'none' }}
						>
							{error}
						</Form.Control.Feedback>
					</InputGroup>
				}
			/>
		</div>
	)
}

export function DatePicker({
	value,
	onChange,
	onPrevYear,
	onNextYear,
	firstAvaliableDate,
	comparisonDay = null,
	withCheck = true,
	...pass
}) {
	const properties = {
		// id: 'datepicker',
		// block: true,
		date: moment(value),
		onDateChange: (date) => {
			onChange(normalizeMomentDate(date))
		},
		focused: true,
		numberOfMonths: 1,
	}

	useEffect(() => {
		moment.locale('ru')
	}, [])
	return (
		<div className={cn('')}>
			<div onClick={onPrevYear} className={cn('button', 'year-prev')}>
				<img alt={'prev'} src={yearArrow} />
			</div>
			<div onClick={onNextYear} className={cn('button', 'year-next')}>
				<img alt={'next'} src={yearArrow} />
			</div>
			<DayPickerSingleDateController
				navNext={
					<div className={cn('button', 'next')}>
						<img alt={'next'} src={arrow} />
					</div>
				}
				navPrev={
					<div className={cn('button', 'prev')}>
						<img alt={'prev'} src={arrow} />
					</div>
				}
				isOutsideRange={(innerDate) => {
					if (!withCheck) return
					if (comparisonDay) {
						return innerDate.isBefore(comparisonDay) // Возвращает true, если innerDate предшествует comparisonDay
					}
				}}
				hideKeyboardShortcutsPanel={true}
				firstDayOfWeek={1}
				renderDayContents={(day) => {
					//Change week day with custom day array
					day._locale._weekdaysMin = [
						'Вс',
						'Пн',
						'Вт',
						'Ср',
						'Чт',
						'Пт',
						'Сб',
					]
					return day.format('D')
				}}
				daySize={32}
				noBorder={true}
				{...pass}
				{...properties}
			/>
		</div>
	)
}
