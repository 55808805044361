import {
	CampaignReportType,
	FinalStatsResponse,
	ReportViewsAndCounts,
} from '../types'
import { parseViewsCounts } from './parseViewsCounts'

const defaultViewsAndCounts: ReportViewsAndCounts = {
	amount: 0,
	fact_cost: 0,
	forecast_views_count: 0,
	views_count: 0,
}

/**
 * @description Mapper for final report
 * @param {FinalStatsResponse} finalReport
 * @returns {Partial<CampaignReportType>}
 */
export const finalReportMapper = (
	finalReport: FinalStatsResponse
): Partial<CampaignReportType> => {
	const report: Partial<CampaignReportType> = {}

	if (
		finalReport.outdoor_adv_is_selected ||
		finalReport.district_adv_is_selected
	) {
		const outdoorBookings = finalReport.booking.filter(
			(booking) => booking.placement.door_type === 'outdoor'
		)

		const outdoor = { ...defaultViewsAndCounts }

		for (const booking of outdoorBookings) {
			const bookingViewsAndCounts = parseViewsCounts(booking)
			outdoor.amount += bookingViewsAndCounts.amount
			outdoor.fact_cost += bookingViewsAndCounts.fact_cost
			outdoor.forecast_views_count +=
				bookingViewsAndCounts.forecast_views_count
			outdoor.views_count += bookingViewsAndCounts.views_count
		}

		for (const districtBooking of finalReport.district_booking) {
			const districtViewAndCounts = parseViewsCounts(districtBooking)
			outdoor.amount += districtViewAndCounts.amount
			outdoor.fact_cost += districtViewAndCounts.fact_cost
			outdoor.forecast_views_count +=
				districtViewAndCounts.forecast_views_count
			outdoor.views_count += districtViewAndCounts.views_count
		}

		report.outdoor = outdoor
	}

	if (finalReport.indoor_adv_is_selected) {
		const indoorBookings = finalReport.booking.filter(
			(booking) => booking.placement.door_type === 'indoor'
		)

		const indoor = { ...defaultViewsAndCounts }

		for (const booking of indoorBookings) {
			const bookingViewsAndCounts = parseViewsCounts(booking)
			indoor.amount += bookingViewsAndCounts.amount
			indoor.fact_cost += bookingViewsAndCounts.fact_cost
			indoor.forecast_views_count +=
				bookingViewsAndCounts.forecast_views_count
			indoor.views_count += bookingViewsAndCounts.views_count

			report.indoor = indoor
		}
	}

	if (
		finalReport.public_transport &&
		finalReport.public_transport.is_selected
	) {
		report.publicTransport = parseViewsCounts(finalReport.public_transport)
	}

	if (finalReport.vk?.is_selected || finalReport.yandex?.is_selected) {
		const internet = { ...defaultViewsAndCounts }

		if (finalReport.vk.is_selected) {
			const parsedVkViewsAndCounts = parseViewsCounts(finalReport.vk)

			internet.amount += parsedVkViewsAndCounts.amount
			internet.fact_cost += parsedVkViewsAndCounts.fact_cost
			internet.forecast_views_count +=
				parsedVkViewsAndCounts.forecast_views_count
			internet.views_count += parsedVkViewsAndCounts.views_count
		}
		if (finalReport.yandex.is_selected) {
			const parsedYandexViewsAndCounts = parseViewsCounts(
				finalReport.yandex
			)

			internet.amount += parsedYandexViewsAndCounts.amount
			internet.fact_cost += parsedYandexViewsAndCounts.fact_cost
			internet.forecast_views_count +=
				parsedYandexViewsAndCounts.forecast_views_count
			internet.views_count += parsedYandexViewsAndCounts.views_count
		}

		report.internet = internet
	}

	if (finalReport.adstream.is_selected) {
		const parsedAdstreamViewsAndCounts = parseViewsCounts(
			finalReport.adstream
		)

		report.tv = parsedAdstreamViewsAndCounts
	}

	return report
}
