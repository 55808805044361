import React, { useEffect, useRef } from 'react'
import { Cell, Column, useTable } from 'react-table'
import { BookingDetailedReportTableData, ShowingRequest } from '../../../types'
import { PhotoReport } from '../photo-report/PhotoReport'
import s from './bookingTableDetails.module.scss'

type Props = {
	columns: readonly Column<BookingDetailedReportTableData>[]
	data: readonly BookingDetailedReportTableData[]
	setPhotoReport: (value: ShowingRequest[] | null) => void
	photoReport: ShowingRequest[] | null
}
export const BookingTableDetails = ({
	columns,
	data,
	photoReport,
	setPhotoReport,
}: Props) => {
	const scrollRef = useRef<HTMLDivElement | null>(null)

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable<BookingDetailedReportTableData>({
			columns,
			data,
		})

	const handleCellClick = (
		cell: Cell<BookingDetailedReportTableData, any>
	) => {
		if (cell.column.id === 'photoReport') {
			const showingRequests = cell.value.showing_requests

			if (!showingRequests?.length) {
				return
			}
			setPhotoReport(showingRequests)
		}
	}
	useEffect(() => {
		const scrollContainer = scrollRef.current

		if (!scrollContainer) return
		const onWheel = (e) => {
			e.preventDefault()
			scrollContainer.scrollLeft += e.deltaY // Adjust horizontal scroll based on vertical mouse wheel movement
		}

		if (scrollContainer) {
			scrollContainer.addEventListener('wheel', onWheel)
		}

		// Cleanup event listener on component unmount
		return () => {
			if (scrollContainer) {
				scrollContainer.removeEventListener('wheel', onWheel)
			}
		}
	}, [])
	return (
		<div style={{ overflow: 'scroll' }} ref={scrollRef}>
			<table {...getTableProps()} className={s.bookingTable}>
				<thead>
					{headerGroups.map((headerGroup, index) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={`header${index}`}
						>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps()}
									key={`headCell${column.Header}`}
									style={{ width: column.width }}
								>
									<div className="d-flex">
										{column.render('Header')}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()} key={`dataRow${row.id}`}>
								{row.cells.map((cell, index) => (
									<td
										{...cell.getCellProps()}
										key={`dataRowCell${cell.column.id}${index}`}
									>
										<div
											onClick={() =>
												handleCellClick(cell)
											}
										>
											{cell.render('Cell')}
										</div>
									</td>
								))}
							</tr>
						)
					})}
				</tbody>
			</table>

			{photoReport && (
				<PhotoReport
					photoReport={photoReport}
					onClose={() => setPhotoReport(null)}
				/>
			)}
		</div>
	)
}
