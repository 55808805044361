import React, { useEffect, useMemo, useState } from 'react'
import { useCampaignReportStore } from '../../store'
import {
	getBookingDetailTableColumns,
	getBookingDetailTableData,
} from '../../utils'
import { ShowingRequest } from '../../types'
import { BookingTableDetails } from './booking-table-details/BookingTableDetails'

type Props = {
	fetchDetails: () => Promise<void>
}

export const IndoorDetails = ({ fetchDetails }: Props) => {
	const [photoReport, setPhotoReport] = useState<ShowingRequest[] | null>(
		null
	)

	const indoor = useCampaignReportStore(
		(store) => store.detailedReport.indoor
	)

	const data = useMemo(
		() => getBookingDetailTableData(indoor, null),
		[indoor]
	)
	const columns = useMemo(
		() => getBookingDetailTableColumns(indoor, null),
		[indoor]
	)

	useEffect(() => {
		fetchDetails()
	}, [])
	return (
		<BookingTableDetails
			columns={columns}
			data={data}
			photoReport={photoReport}
			setPhotoReport={setPhotoReport}
		/>
	)
}
