import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import s from './autoPostingLegalInfo.module.scss'
import FormLabelTooltip from 'src/components/UtilityComponents/FormLabelTooltip'
import useCampaignData from 'src/stores/campaignData'
import useDebounce from 'src/utils/useDebounce'

const tooltip =
	'Оставьте включенным только в том случае, если на ваших рекламных материалах не указана юридическая информация. Мы заполним ее автоматически, на основании заполненных данных о вашем бизнесе.'

export const AutoPostingLegalInfo = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [autoPostingLegalInfo, setAutoPostingLegalInfo] = useState(
		campaign.auto_posting_legal_info
	)

	const debeouncedAutoPosting = useDebounce(autoPostingLegalInfo, 300)

	const toggleAutoPosting = async (autoPostingLegalInfo: boolean) => {
		await campaignInterface.toggleAutoPostingLegalInfo(
			campaign.id,
			autoPostingLegalInfo
		)
		await campaignInterface.refetchSelected()
	}

	useEffect(() => {
		if (campaign?.auto_posting_legal_info !== debeouncedAutoPosting) {
			toggleAutoPosting(autoPostingLegalInfo)
		}
	}, [debeouncedAutoPosting])

	return (
		<Card className={s.autoPostingLegalInfoCard}>
			<div className={s.autoPostingLegalInfoContainer}>
				<h2>Загрузка рекламных материалов</h2>

				<div className={s.autoPostingLegalInfoBody}>
					<Form.Switch
						onClick={() =>
							setAutoPostingLegalInfo(!autoPostingLegalInfo)
						}
					/>
					<div className={s.autoPostingText}>
						<div className={s.autoPostingTextTitleContainer}>
							<p className={s.autoPostingTextTitle}>
								Авторазмещение юридической информации в
								рекламных материалах
							</p>
							<FormLabelTooltip text={tooltip} />
						</div>

						<div className={s.autoPostingTextInfo}>
							<p>
								В соответствии с федеральным законом «О
								рекламе»,{' '}
								<a
									href="https://www.consultant.ru/document/cons_doc_LAW_58968/"
									target="_blank"
									rel="noreferrer"
								>
									N 38-ФЗ от 13.03.2006
								</a>{' '}
								, на рекламной конструкции должны быть указаны:
							</p>
							<ul>
								<li>Возрастная маркировка</li>
								<li>Пометка «Реклама»</li>
								<li>Название Юр. лица / Самозанятого</li>
								<li>
									Адрес регистрации Юр. лица / Самозанятого
								</li>
								<li>ИНН Юр. лица / Самозанятого</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Card>
	)
}
