import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { CampaignReportTable } from '../campaign-report-table/CampaignReportTable'
import { ReactComponent as ExcelIcon } from 'src/assets/images/xls.svg'
import s from './campaignReportTableCard.module.scss'
import { useParams } from 'react-router-dom'
import { downloadByRequest } from 'src/utils'

export const CampaignReportTableCard = (props: any) => {
	const { campaignId } = useParams<{ campaignId: string }>()

	const handleDownload = async () => {
		if (campaignId) {
			const url = `adv_companies/${campaignId}/final-stats/export/`

			await downloadByRequest(url, 'Отчет', 'POST')
		}
	}

	return (
		<Card className={s.reportCard}>
			<div className={s.header}>
				<h3>Отчет о завершенной рекламной кампании</h3>
				<Button variant={'light'} onClick={handleDownload}>
					<ExcelIcon />
					<h6> Скачать отчет в Excel</h6>
				</Button>
			</div>

			<CampaignReportTable />
		</Card>
	)
}
