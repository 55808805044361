import React, { useEffect, useMemo, useState } from 'react'
import { useCampaignReportStore } from '../../store'
import {
	getBookingDetailTableColumns,
	getBookingDetailTableData,
} from '../../utils'
import { Cell, useTable } from 'react-table'
import { BookingDetailedReportTableData, ShowingRequest } from '../../types'
import { PhotoReport } from './photo-report/PhotoReport'
import { BookingTableDetails } from './booking-table-details/BookingTableDetails'

type Props = {
	fetchDetails: () => Promise<void>
}

export const OutdoorDetails = ({ fetchDetails }: Props) => {
	const [photoReport, setPhotoReport] = useState<ShowingRequest[] | null>(
		null
	)

	const outdoor = useCampaignReportStore(
		(store) => store.detailedReport.outdoor
	)
	const districts = useCampaignReportStore(
		(store) => store.detailedReport.district_booking
	)

	const data = useMemo(
		() => getBookingDetailTableData(outdoor, districts),
		[outdoor, districts]
	)
	const columns = useMemo(
		() => getBookingDetailTableColumns(outdoor, districts),
		[outdoor, districts]
	)

	useEffect(() => {
		fetchDetails()
	}, [])
	return (
		<BookingTableDetails
			columns={columns}
			data={data}
			photoReport={photoReport}
			setPhotoReport={setPhotoReport}
		/>
	)
}
