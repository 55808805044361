import React from 'react'
import {
	BookingDetailedReportTableData,
	BookingReportDetail,
	DistrictBookingReportDetail,
} from '../types'
import { Column } from 'react-table'
import moment from 'moment'
import { PhotoReportPreview } from '../ui/adv-format-details/photo-report/PhotoReportPreview'
import { defaultPlacementImage } from 'src/constants/defaultPlacementImageUrl'

export const getBookingDetailTableColumns = (
	bookings: BookingReportDetail[] | null,
	districtBookings: DistrictBookingReportDetail[] | null
): Column<BookingDetailedReportTableData>[] => {
	const days = [] as string[]

	if (Array.isArray(bookings)) {
		const bookingWithStat = bookings.find(
			(booking) => booking.stat_log.length > 0
		)
		if (bookingWithStat) {
			for (const stat of bookingWithStat.stat_log) {
				days.push(moment(stat.date, 'YYYY-MM-DD').format('D MMMM'))
			}
		}
	} else if (Array.isArray(districtBookings)) {
		const districtWithStat = districtBookings.find(
			(district) => district.stat_log.length > 0
		)
		if (districtWithStat) {
			for (const stat of districtWithStat.stat_log) {
				days.push(moment(stat.date, 'YYYY-MM-DD').format('D MMMM'))
			}
		}
	}

	return [
		{
			Header: 'Рекламное место',
			accessor: 'placement',
			Cell: ({ value }) => <p>{value.name}</p>,
			width: 340,
		},
		{
			Header: 'Фотоотчет',
			accessor: 'photoReport',
			Cell: ({ value }) =>
				value.photoCount ? (
					<PhotoReportPreview
						imageUrl={value.previewUlr}
						totalCount={value.photoCount}
					/>
				) : (
					<h6 className="m-0">Нет фото</h6>
				),

			width: 160,
		},
		...days.map((day) => ({
			Header: day,
			accessor: day,
			Cell: ({ value }) => <p>{value}</p>,
		})),
	]
}

export const getBookingDetailTableData = (
	bookings: BookingReportDetail[] | null,
	districtBookings: DistrictBookingReportDetail[] | null
): BookingDetailedReportTableData[] => {
	const data = [] as BookingDetailedReportTableData[]

	moment.locale('ru')

	if (Array.isArray(bookings)) {
		const bookingsData = bookings.map((booking) => {
			const firstFindedPhotoReport = booking.showing_requests.find(
				(show) => !!show.photo_report
			)

			const preview =
				firstFindedPhotoReport?.photo_report || defaultPlacementImage
			const tableBooking: BookingDetailedReportTableData = {
				placement: {
					name: booking.placement.name,
					placementType: booking.placement.placement_type.name,
				},
				photoReport: {
					photoCount:
						booking.showing_requests.filter(
							(show) => show.photo_report
						).length || 0,
					previewUlr: preview,
					showing_requests: booking.showing_requests,
				},
			}

			for (const stat of booking.stat_log) {
				Object.assign(tableBooking, {
					[moment(stat.date, 'YYYY-MM-DD').format('D MMMM')]:
						stat.views_count,
				})
			}

			return tableBooking
		})

		data.push(...bookingsData)
	}

	if (Array.isArray(districtBookings)) {
		const districtsData = districtBookings.map((district) => {
			const firstFindedPhotoReport = district.showing_requests.find(
				(show) => !!show.photo_report
			)

			const preview =
				firstFindedPhotoReport?.photo_report || defaultPlacementImage
			const tableDistrict: BookingDetailedReportTableData = {
				placement: {
					name: district.city_name,
					placementType: '',
				},
				photoReport: {
					photoCount:
						district.showing_requests.filter(
							(show) => show.photo_report
						).length || 0,
					previewUlr: preview,
					showing_requests: district.showing_requests,
				},
			}

			for (const stat of district.stat_log) {
				Object.assign(tableDistrict, {
					[moment(stat.date, 'YYYY-MM-DD').format('D MMMM')]:
						stat.views_count,
				})
			}

			return tableDistrict
		})

		data.push(...districtsData)
	}

	return data
}
